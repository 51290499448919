import * as React from 'react';
import './App.scss';

class App extends React.Component {
  public render() {
    return (
      <div id="container">
        <div id="intro">
          <div className="container">
            <div className="title">
              <h1>Tvořím moderní webové aplikace</h1>
              <h2>Ing. Vladimír Bátrla</h2>
              <p>
                email: vladimir@batrla.eu
                </p>
            </div>
          </div>
        </div>
        <div>
          <div id="tech">
            <div className="icon icon-netcore">&nbsp;</div>
            <div className="icon icon-ts">&nbsp;</div>
            <div className="icon icon-sql">&nbsp;</div>
            <div className="icon icon-react">&nbsp;</div>
            <div className="icon icon-ms">&nbsp;</div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
